import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { richtextDefaultOptions } from "../components/RichTextOptions"
import { ContentfulImage, ContentfulRichText } from "../shared/ContentfulTypes"
import { useResizeObserver } from "../utils/ResizeObserver"

type Data = {
  image: ContentfulImage
  text: ContentfulRichText
}
type BannerProps = {
  data: Data
}

const Banner: React.FC<BannerProps> = ({ data }) => {
  const { image, text } = data
  const textRef = useRef(null)
  // const [height] = useResizeObserver(textRef)
  const [width, height] = useResizeObserver(textRef)

  return (
    <StyledSection>
      <div></div>
      <Background height={height && height}>
        <GatsbyImage
          style={{ width: "100%", height: "100%" }}
          imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt={image?.description}
          image={image.gatsbyImageData}
        />
      </Background>

      <Text ref={textRef}>
        <RichText>{renderRichText(text, richtextDefaultOptions)}</RichText>
      </Text>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1.5fr 1fr;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 1.9fr 1fr;
  }
`
const Background = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
  height: ${(props) => `${props.height}px`};

  @media (min-width: 900px) {
    left: unset;
    transform: unset;
    right: 30%;
    width: 70vw;
    z-index: -2;
  }
`

const Text = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-top: 5rem;
  padding-bottom: 4rem;
  position: relative;
  z-index: 0;
  background-color: white;

  @media (min-width: 900px) {
    transform: skew(-10deg);
  }
  p {
    margin: 2rem 0;
  }
`

const RichText = styled.div`
  width: 100%;

  @media (min-width: 900px) {
    max-width: 40rem;
    transform: skew(10deg);
  }
`
export default Banner
