import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { ContentfulSectionHero } from "../shared/ContentfulTypes"
import { useResizeObserver } from "../utils/ResizeObserver"

type HeroProps = {
  data: ContentfulSectionHero
}
const headingSizes = {
  small: `var(--headingSmall)`,
  medium: "var(--headingMedium)",
  large: "var(--headingLarge)",
}

const Hero: React.FC<HeroProps> = ({ data }) => {
  const {
    image,
    heading,
    description,
    style,
    mediaType,
    mobileImageHomepage,
    headingSize,
  } = data
  const heroStyle = style === "Split view" ? "split" : "whole"
  const [windowWidth, setWindowWidth] = useState(0)
  const textRef = useRef(null)
  const [width, height] = useResizeObserver(textRef)

  useEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () => setWindowWidth(window.innerWidth)
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const vidRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.volume = 0
    }
  }, [])

  return (
    <StyledSection
      heroStyle={heroStyle}
      headingSize={headingSize}
      height={height}
    >
      {heroStyle === "whole" && (
        <HeroTextWrapper>
          <div>
            <h1>{heading}</h1>
            <p>{description}</p>
          </div>
        </HeroTextWrapper>
      )}
      {heroStyle === "split" && (
        <HeroTextWrapperSplit ref={textRef}>
          <StyledTextContent>
            <h1>{heading}</h1>
            <p>{description}</p>
          </StyledTextContent>
        </HeroTextWrapperSplit>
      )}

      <HeroImageWrapper>
        {mediaType === "Video" && windowWidth > 900 ? (
          <video
            ref={vidRef}
            src={`${image.file.url}?autoplay=1&mute=1`}
            autoPlay={true}
            loop={true}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <GatsbyImage
            alt={
              mediaType === "Video"
                ? mobileImageHomepage?.description
                : image?.description
            }
            image={
              mediaType === "Video"
                ? mobileImageHomepage?.gatsbyImageData
                : image?.gatsbyImageData
            }
            style={{
              width: "100%",
              height: "100%",
            }}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        )}
      </HeroImageWrapper>
    </StyledSection>
  )
}
type StyledSectionProps = {
  headingSize?: "small" | "medium" | "large"
  heroStyle?: "split" | "whole"
  height: number
}
const StyledSection = styled.section<StyledSectionProps>`
  height: ${(props) =>
    props.heroStyle === "split" ? `${props.height / 10}rem` : "80vh"};
  max-height: ${(props) =>
    props.heroStyle === "split" ? `${props.height / 10}rem` : "87rem"};
  width: 100%;
  position: relative;
  padding-right: 50px;

  h1 {
    ${(props) => {
      if (props.heroStyle === "split") {
        return `
        margin-bottom: 2rem;
        font-size: 3.2rem;
        line-height: 4.2rem;
        @media (min-width: 768px) {
          font-size: 6.4rem;
          line-height: 7.2rem;
          margin-bottom: 3rem;
        }
        `
      } else {
        return `
        margin-bottom: 4rem;
        font-size: 4.8rem;
        line-height: 5.8rem;
        @media (min-width: 1440px) {

        }
        @media (min-width: 1600px) {
          font-size: 6.4rem;
          line-height: 6.4rem;
        }
        `
      }
    }}

    font-size: ${(props) =>
      props.headingSize && headingSizes[props.headingSize]};
    @media (min-width: 768px) {
      font-size: ${(props) => {
        if (props.headingSize) {
          return headingSizes[props.headingSize]
        }
      }};
    }
    @media (min-width: 1440px) {
      font-size: ${(props) => {
        if (props.headingSize) {
          return headingSizes[props.headingSize]
        }
      }};
    }
    @media (min-width: 1600px) {
      font-size: ${(props) => {
        if (props.headingSize) {
          return headingSizes[props.headingSize]
        }
      }};
    }
  }
`
const HeroTextWrapper = styled.div`
  color: #e6f0f0;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 650px) {
    p {
      font-size: 3rem;
      line-height: 3.3rem;
    }
  }
`

const HeroTextWrapperSplit = styled.div`
  width: 100%;
  position: relative;
  min-height: 60rem;
  padding-bottom: 2rem;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    background-color: black;
    height: 100%;
    width: 100vw;
    left: 20%;
    transform: skew(-20deg) translateX(-50%);
    z-index: 2;
    @media (max-width: 768px) {
      transform: skew(-20deg) translateX(-50%);
      width: 150vw;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
  @media (min-width: 768px) {
    width: 65%;
  }
  @media (min-width: 1440px) {
  }

  p {
    font-size: 2rem;
    line-height: 3rem;
  }
`
const StyledTextContent = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;

  color: #ffffff;

  padding-right: 3rem;
  padding-left: 1rem;
  padding-top: 8rem;

  @media (min-width: 1440px) {
    padding-top: 15%;
  }
`
const HeroImageWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  :before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`

export {
  StyledTextContent,
  HeroTextWrapper,
  HeroImageWrapper,
  HeroTextWrapperSplit,
}

export default Hero
