import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { BarCodeType, ContentfulRichText } from "../shared/ContentfulTypes"
import BarCode from "./BarCode"

import { StaticImage } from "gatsby-plugin-image"
import UseGetScreenSize from "../hooks/UseGetScreenSize"
import { renderRichText } from "gatsby-source-contentful/rich-text"
type BarCodeSliderProps = {
  headsUp: ContentfulRichText
  barCodes: BarCodeType[]
}
const BarCodeSlider: React.FC<BarCodeSliderProps> = ({ barCodes, headsUp }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [scrollWidth, setScrollWidth] = useState()
  const [pagination, setPagination] = useState<Number>(1)
  const screenWidth = UseGetScreenSize()

  const scroll = (direction: number) => {
    if (containerRef.current && scrollWidth) {
      containerRef.current.scrollLeft += scrollWidth * direction
    }
  }
  const handleScroll = (
    scrollPosition: number,
    elementWidth: number,
    maxScroll: number
  ) => {
    const newPosition = scrollPosition + 100
    const start = Math.floor(containerRef.current?.offsetWidth / elementWidth)
    if (scrollPosition === maxScroll) {
      setPagination(barCodes.length)
    } else if (newPosition > elementWidth) {
      setPagination(Math.floor(newPosition / elementWidth + start))
    } else {
      setPagination(start)
    }
  }

  useEffect(() => {
    if (containerRef.current && scrollWidth) {
      const maxScroll =
        containerRef.current.scrollWidth - containerRef.current.clientWidth
      handleScroll(containerRef.current.scrollLeft, scrollWidth, maxScroll)
      containerRef.current.addEventListener("scroll", () => {
        handleScroll(containerRef.current.scrollLeft, scrollWidth, maxScroll)
      })
      return containerRef.current.removeEventListener("scroll", handleScroll)
    }
  }, [containerRef, scrollWidth])
  return (
    <StyledSlider>
      <HeadsUp>
        <StaticImage
          imgStyle={{ height: "20px", width: "20px" }}
          src="../images/notice.png"
          alt="information"
        />
        <div>{renderRichText(headsUp)}</div>
      </HeadsUp>
      <Container ref={containerRef}>
        {barCodes.map((card, i) => {
          return (
            <BarCode
              key={i}
              setScrollWidth={setScrollWidth}
              i={i}
              data={card}
            />
          )
        })}
      </Container>
      {screenWidth && screenWidth.width < 740 && (
        <Pagination>
          <DashBoard>
            <Button onClick={() => scroll(-1)}>
              <StaticImage src="../images/arrow.png" alt="arrow left" />
            </Button>
            <Number>
              {pagination} / {barCodes.length}
            </Number>
            <Button onClick={() => scroll(1)}>
              <StaticImage src="../images/arrow.png" alt="arrow right" />
            </Button>
          </DashBoard>
        </Pagination>
      )}
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  margin-right: -25px;
`
const HeadsUp = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  margin-right: 25px;
  z-index: 2;
  padding: 20px 0px;
  h5,
  h3 {
    font-size: 16px;
    margin: 0;
  }
  p {
    color: black;
    z-index: 2;
  }
  &:before {
    content: "";
    background-color: #e6f0f0;
    position: absolute;
    width: 100vw;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1;
    padding: 20px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`
const Container = styled.div`
  display: flex;
  overflow: scroll;
  gap: 8px;
  scroll-behavior: smooth;
  padding-top: 32px;

  @media (min-width: 740px) {
    flex-wrap: wrap;
  }
`

const Number = styled.p`
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
`
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 18px;
`
const DashBoard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 170px;
`
const Button = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 5px 5px 14px -9px rgba(0, 0, 0, 0.45);
  box-shadow: 5px 5px 14px -9px rgba(0, 0, 0, 0.45);
  &:nth-of-type(2) {
    transform: rotate(180deg);
  }
`
export default BarCodeSlider
