import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { BarCodeType } from "../shared/ContentfulTypes"

type BarCodeProps = {
  data: BarCodeType
  i: number
  setScrollWidth: React.SetStateAction
}

const BarCode: React.FC<BarCodeProps> = ({ data, i, setScrollWidth }) => {
  const { image, barCode, price, donation } = data
  const elementRef = useRef<HTMLDivElement>(null)
  const [barCodeVisible, setBarCodeVisible] = useState(false)

  useEffect(() => {
    if (elementRef.current && i === 0) {
      setScrollWidth(elementRef.current.offsetWidth)
    }
  }, [elementRef])
  const BarCodeModal = () => {
    return (
      <StyledBarCodeModal>
        <div className="modalContent">
          <div
            className="closeModal"
            onClick={() => setBarCodeVisible(false)}
          />
          <GatsbyImage
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt={barCode.description}
            image={barCode.gatsbyImageData}
          />
        </div>
      </StyledBarCodeModal>
    )
  }

  return (
    <StyledBarCode ref={elementRef}>
      {barCodeVisible && <BarCodeModal />}
      <Left>
        <GatsbyImage
          style={{
            width: "60px",
            height: "60px",
            marginRight: "15px",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          alt={image?.description}
          image={image.gatsbyImageData}
        />
      </Left>
      <Right onClick={() => setBarCodeVisible(true)}>
        <div>
          <p>{donation}</p>
          <p>{price}</p>
        </div>
        <Code>
          <GatsbyImage
            style={{ width: "100%", height: "100%" }}
            imgStyle={{
              width: "100%",
              height: "100%",
            }}
            alt={barCode.description}
            image={barCode.gatsbyImageData}
          />
        </Code>
      </Right>
    </StyledBarCode>
  )
}

const StyledBarCode = styled.div`
  * {
    color: black;
  }
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  height: 192px;
  padding: 16px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 14px -9px rgba(0, 0, 0, 0.45);
  box-shadow: 5px 5px 14px -9px rgba(0, 0, 0, 0.45);
  display: grid;
  grid-template-columns: auto 1fr;
  @media (min-width: 1500px) {
    width: 348px;
  }
  cursor: pointer;
`
const Left = styled.div``

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: 16px;
    &:first-of-type {
      font-weight: bold;
    }
  }
`
const Code = styled.div`
  height: 80px;
`

const StyledBarCodeModal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);

  .modalContent {
    background-color: #f2f7f7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    border-radius: 8px;
    width: 500px;
    z-index: 2;

    @media (max-width: 768px) {
      width: 80%;
    }
  }

  .closeModal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    opacity: 0.3;
  }

  .closeModal:hover {
    opacity: 1;
  }

  .closeModal:before,
  .closeModal:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #7700e6;
  }

  .closeModal:before {
    transform: rotate(45deg);
  }
  .closeModal:after {
    transform: rotate(-45deg);
  }
`
export default BarCode
