import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { useState } from "react"
import { ContentfulSectionTryCheckout } from "../shared/ContentfulTypes"
import styled from "styled-components"
import BG from "../images/bg-1-corner.png"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Button from "../components/Button"

type TryCheckoutProps = {
  data: ContentfulSectionTryCheckout
}
const TryCheckout: React.FC<TryCheckoutProps> = ({ data }) => {
  const {
    image,
    text,
    qrCode,
    url,
    textMobile,
    usingMobile,
    mobileUserInformationHeading,
  } = data
  const [linkCopied, setLinkCopied] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Focalpay self-scanning",

          url,
        })
        .then(() => {
          console.log("Successfully shared")
        })
        .catch((error) => {
          console.error("Something went wrong", error)
        })
    }
    return
  }

  const handleClipboard = () => {
    navigator.clipboard.writeText(url)
    setLinkCopied(true)
  }

  return (
    <StyledSection>
      {popupVisible && (
        <>
          <Overlay />
          <Popup>
            <div>{renderRichText(usingMobile)}</div>
            <div onClick={() => setPopupVisible(false)}>
              <Button type="primary">Close</Button>
            </div>
          </Popup>
        </>
      )}

      <StyledTextWrapper>
        <StyledTextContent>
          <Desktop>{renderRichText(text)}</Desktop>
          <Mobile>{renderRichText(textMobile)}</Mobile>
        </StyledTextContent>
      </StyledTextWrapper>
      <StyledBackground>
        <StyledQrCode>
          <GatsbyImage
            alt={qrCode?.description}
            image={qrCode?.gatsbyImageData}
            style={{
              width: "300px",
              height: "300px",
            }}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
            }}
          />
        </StyledQrCode>

        <StyledCodeActions>
          <MobileLayout>
            <Notice onClick={() => setPopupVisible(true)}>
              <StaticImage
                style={{ marginRight: "10px" }}
                imgStyle={{ height: "20px", width: "20px" }}
                alt="notice"
                src="../images/notice2.png"
              />
              <p>{mobileUserInformationHeading}</p>
            </Notice>

            <Button type="primary">
              <ButtonLink target="__blank" href={url}>
                Open self-scanning
              </ButtonLink>
            </Button>
            <Button type="secondary">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StaticImage
                  style={{ height: "20px", width: "20px", marginRight: "5px" }}
                  imgStyle={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt="share"
                  src="../images/share2.svg"
                />
                <ButtonLink onClick={handleShare}>Share</ButtonLink>
              </div>
            </Button>
          </MobileLayout>
          <DesktopLayout>
            <p>Opens self scanning</p>
            <CopyLink>
              <StaticImage
                alt="copy link to self scanning"
                style={{ marginRight: "10px" }}
                imgStyle={{ width: "18px", height: "20px" }}
                src="../images/share.png"
              />
              <a onClick={handleClipboard}>
                {linkCopied ? "Copied!" : "Copy Link"}
              </a>
            </CopyLink>
          </DesktopLayout>
        </StyledCodeActions>
      </StyledBackground>
    </StyledSection>
  )
}

const Mobile = styled.div`
  @media (min-width: 1400px) {
    display: none;
  }
`
const Desktop = styled.div`
  display: none;
  @media (min-width: 1400px) {
    display: unset;
  }
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a8;
  z-index: 4;
`
const Popup = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 420px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    color: black;
  }
  h3 {
    font-family: F37 Lineca, sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`
const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;

    left: 50%;
    transform: translateX(-50%);
    background: black;
    z-index: -1;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    padding: unset;
  }
`

const StyledTextWrapper = styled.div`
  height: 100%;
  color: white;
  width: 100%;
  padding-right: 20px;
  @media (min-width: 900px) {
  }
`
const StyledTextContent = styled.div`
  height: 100%;
  display: flex;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  div {
    h2 {
      font-family: F37 Lineca Bold, sans-serif;
      margin-bottom: 30px;
      color: #ffffff;
    }
    p {
      font-family: F37 Lineca, sans-serif;
      color: #ffffff;
    }
    ol {
      font-family: F37 Lineca, sans-serif;
      list-style-type: decimal;
      color: #ffffff;
    }
    li {
      margin-bottom: 10px;
    }
  }
`
const StyledBackground = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    left: 0;
    display: none;
    @media (min-width: 900px) {
      display: unset;
      background-image: url(${BG});
    }
    background-size: 50% 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 200px 0px;
  }
`
const StyledQrCode = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: unset;
  }
`

const StyledCodeActions = styled.div`
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 0 8px 8px 0;

  width: 100%;

  a {
    font-family: F37 Lineca Bold, sans-serif;
    cursor: pointer;
  }
  a:last-child {
    margin-bottom: 0;
  }
`
const CopyLink = styled.div``
const DesktopLayout = styled.div`
  display: none;
  p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  a {
    color: #da98ff;
  }
  @media (min-width: 900px) {
    display: unset;
  }
`
const MobileLayout = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`
const ButtonLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Notice = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  p {
    color: #da98ff;
    font-weight: bold;
  }
  @media (min-width: 900px) {
    display: none;
  }
`
export default TryCheckout
