import Hero from "../sections/Hero"
import TextImagesText from "../sections/TextImagesAndText"
import ThreeColumnsText from "../sections/ThreeColumnsText"
import FourColumnsText from "../sections/FourColumnsText"
import React from "react"
import TextImage from "../sections/TextImage"
import TryCheckout from "../sections/TryCheckout"
import ProductSelection from "../sections/ProductSelection"
import FormSection from "../sections/FormSection"
import Team from "../sections/Team"
import HeroNew from "../sections/HeroNew"
import List from "../sections/List"
import Banner from "../sections/Banner"
import Carousel from "../components/Carousel"
import SalesforceForm from "../components/SalesforceForm"
import Video from "../sections/Video"

export type Components = {
  [sectionName: string]: React.ElementType
}

export const SectionComponents: Components = {
  ContentfulSectionHero: Hero,
  ContentfulSectionThreeColumnsText: ThreeColumnsText,
  ContentfulSectionPartnerLogos: TextImagesText,
  ContentfulSection4ColumnsText: FourColumnsText,
  ContentfulSectionTextImage: TextImage,
  ContentfulSectionTryCheckout: TryCheckout,
  ContentfulSectionProductSelection: ProductSelection,
  ContentfulForm: FormSection,
  ContentfulSectionTeam: Team,
  ContentfulSectionHeroNew: HeroNew,
  ContentfulSectionList: List,
  ContentfulSectionBanner: Banner,
  ContentfulSlider: Carousel,
  ContentfulSalesforceForm: SalesforceForm,
  ContentfulSectionVideo: Video,
}
