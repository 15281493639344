import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import FullWidthSingleColor from "../components/FullWidthSingleColor"

import { richtextDefaultOptions } from "../components/RichTextOptions"
import { ContentfulImage, SectionTextImage } from "../shared/ContentfulTypes"
import { mode, SectionParent } from "../shared/Styles"
import { SectionSubHeading, StyledSectionTitle } from "../shared/Typograpy"

type TextImageProps = {
  data: SectionTextImage
}
const TextImage: React.FC<TextImageProps> = ({ data }) => {
  const {
    heading,
    heading2,

    image,
    imageMobile,

    display,
    mode,
  } = data

  const flexDirection = display === "Text first" ? "row-reverse" : "row"

  const [windowWidth, setWindowWidth] = useState(0)
  const [imageToDisplay, setImageToDisplay] = useState<ContentfulImage>()

  useEffect(() => {}, [])

  useEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () => setWindowWidth(window.innerWidth)
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  useEffect(() => {
    if (windowWidth > 900) {
      setImageToDisplay(image)
    } else if (windowWidth < 900) {
      imageMobile && setImageToDisplay(imageMobile)
    }
  }, [windowWidth])

  const renderImage = () => {
    if (imageToDisplay?.file?.url?.includes(".svg")) {
      return <SVGImage src={imageToDisplay?.file.url} />
    } else {
      return (
        <GatsbyImage
          alt={image?.description}
          image={image?.gatsbyImageData}
          style={{
            width: "100%",
            height: "100%",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      )
    }
  }
  return (
    <StyledSection mode={mode ? mode : "Light"}>
      <FullWidthSingleColor mode={mode ? mode : "Light"} />
      <StyledWrapper flexDirection={flexDirection}>
        {imageToDisplay && <ImageWrapper>{renderImage()}</ImageWrapper>}

        <StyledText>
          <div>
            <StyledSectionTitle>{heading}</StyledSectionTitle>
            <SectionSubHeading>{heading2}</SectionSubHeading>
          </div>
          <RichText>
            {renderRichText(data.text, richtextDefaultOptions)}
          </RichText>
          <StyledButtonWrapper>
            {/* {cta && ctaLinksTo && (
              <Button className="submitBtn" cta={cta} ctaLinksTo={ctaLinksTo} />
            )} */}
          </StyledButtonWrapper>
        </StyledText>
      </StyledWrapper>
    </StyledSection>
  )
}

type StyledWrapperProps = {
  flexDirection: "row" | "row-reverse"
}

const StyledSection = styled(SectionParent)`
  width: 100%;
  height: 100%;
  padding: var(--sectionPadding);
  position: relative;

  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`
const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  flex-direction: ${(props) => `${props.flexDirection}`};
  justify-content: space-between;
  gap: 150px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 40px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  overflow: visible;
`
const SVGImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const StyledText = styled.div`
  width: 100%;

  max-width: 80rem;

  h4 {
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin: 10px 0px;
  }
  ul {
    p {
      margin: unset;
      margin-bottom: 10px;
    }
  }
`

const RichText = styled.div`
  picture {
    img {
      max-width: 50%;
    }
  }
`
const StyledButtonWrapper = styled.div`
  width: 40%;
  margin-top: 50px;
`
export default TextImage
