import { graphql, useStaticQuery } from "gatsby"

export const useEvents = () => {
  const Events = useStaticQuery(graphql`
    {
      allContentfulNewsEvents(
        sort: { fields: date, order: DESC }
        filter: { type: { eq: "Event" }, node_locale: { eq: "en-US" } }
      ) {
        nodes {
          ...Event
        }
      }
    }
  `)
  return Events.allContentfulNewsEvents.nodes
}
