import React, { useRef, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { ContentfulRichText } from "../shared/ContentfulTypes"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { SectionParent } from "../shared/Styles"
import FullWidthSingleColor from "../components/FullWidthSingleColor"

type Data = {
  heading: string
  text: ContentfulRichText
  videoEmbed: string
  mode?: "Dark" | "Light"
}
type VideoProps = {
  data: Data
}
const Video: React.FC<VideoProps> = ({ data }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <SectionWrapper mode={data?.mode ? data?.mode : "Grey"}>
      <FullWidthSingleColor mode={data?.mode ? data?.mode : "Grey"} />
      <TextContent>
        <h2>{data.heading}</h2>
        {renderRichText(data.text)}
      </TextContent>
      <VideoWrapper>
        <Player
          style={{ height: "100%", width: "100%" }}
          playing={isPlaying}
          ref={videoRef}
          url={data.videoEmbed}
        />
      </VideoWrapper>
    </SectionWrapper>
  )
}
const SectionWrapper = styled(SectionParent)`
  padding: 9.6rem 0;
`
const TextContent = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  a {
    font-weight: bold;
  }
  p {
    max-width: 64rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
  }
`
const VideoWrapper = styled.div`
  max-width: 112rem;
  aspect-ratio: 16/9;
  margin: 0 auto;
`
const Player = styled(ReactPlayer)`
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
`
const IconWrapper = styled.div`
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
`

const PlayButton = styled.button`
  background-color: var(--purple2);
  font-size: 1.6rem;
  padding: 1.2rem 3.2rem;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
`
export default Video
