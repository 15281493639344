import { Link } from "gatsby"
import { IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import FullWidthSingleColor from "../components/FullWidthSingleColor"
import NewsCard from "../components/NewsCard"
import { useEvents } from "../hooks/useEvents"
import { useNews } from "../hooks/useNews"
import { hoverGrow } from "../shared/Animation"
import { Title7 } from "../shared/Typograpy"
import { ContentfulRichText } from "../shared/ContentfulTypes"

type Data = {
  type: NewsTypes
  heading: string
  mode: "Dark" | "Light"
}
type ListProps = {
  data: Data
}

type NewsTypes = "News" | "Events"

type Pages = {
  [key: number]: NewsEvent[]
}
type NewsEvent = {
  type: NewsTypes
  heading: string
  thumnnailHeading: string
  description: string
  thumbnailImage: IGatsbyImageData
  month: string
  day: string
  time: string
  body: ContentfulRichText
  width: string
}

const listTypes = {
  Events: NewsCard,
  News: NewsCard,
}
const List: React.FC<ListProps> = ({ data }) => {
  const { type, mode } = data
  const listItems: NewsEvent[] = type === "News" ? useNews() : useEvents()

  const [gridSlots, setGridSlots] = useState(8)
  const [width, setWidth] = useState<number>()
  const [lessPadding, setLessPadding] = useState(false)

  const [pages, setPages] = useState<Pages>({ 1: [] })
  const [pageIndex, setPageIndex] = useState(1)

  const bigArray = [...listItems]

  useEffect(() => {
    if (typeof window === "undefined") return
    setWidth(window.innerWidth)
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (width) {
      if (width < 750) {
        setGridSlots(3)
        return
      }
      if (width < 1032) {
        setGridSlots(4)
        return
      }
      if (width < 1367) {
        setGridSlots(6)
        return
      } else {
        setGridSlots(8)
        return
      }
    }
  }, [width])

  useEffect(() => {
    if (listItems.length > 1) {
      pageDivider()
    } else {
      setPages({ 1: listItems })
    }
  }, [gridSlots])

  const pageDivider = () => {
    if (listItems.length) {
      const pages: Pages = {}
      const columns = gridSlots / 2
      let slotCounter = 0
      let columnCount = 0
      let page = 1
      let pageContent: NewsEvent[] = []
      bigArray.forEach((item, index) => {
        const newsItem: NewsEvent = { ...item }
        columnCount += parseInt(newsItem.width)
        if (columnCount > columns) {
          newsItem.width = "1"
          columnCount = 0
        }
        if (columnCount === columns) {
          columnCount = 0
        }
        slotCounter += parseInt(newsItem.width)
        pageContent.push(newsItem)
        if (slotCounter === gridSlots) {
          pages[page] = pageContent
          pageContent = []
          page++
          slotCounter = 0
        }
        if (index === bigArray.length - 1) {
          pages[page] = pageContent
          setPages(pages)
        }
      })
    }
  }

  //set less padding if only one row
  useEffect(() => {
    if (Object.keys(pages).length === 1) {
      let count = 0
      pages[1].forEach((el) => (count += parseInt(el.width)))
      if (count < 5) {
        setLessPadding(true)
      } else {
        setLessPadding(false)
      }
    }
  }, [pages])

  const renderPagination = () => {
    let buttons = []
    const pageLength = Object.keys(pages).length

    const isEndOfArray = (currentPage) => {
      if (currentPage > pageLength) {
        return null
      } else {
        return currentPage
      }
    }
    if (pageIndex === 1) {
      buttons = [
        pageIndex,
        isEndOfArray(pageIndex + 1),
        isEndOfArray(pageIndex + 2),
      ]
    }
    if (pageIndex > 1) {
      buttons = [pageIndex - 1, pageIndex, isEndOfArray(pageIndex + 1)]
    }

    return buttons
  }

  return (
    <StyledSection mode={mode}>
      <FullWidthSingleColor mode={mode} />
      <h1>{data.heading}</h1>
      <StyledGrid lessPadding={lessPadding}>
        {pages[pageIndex]?.map((item, i) => {
          const Card = listTypes[type]

          return (
            <CardWrapper
              to={`/${item.type.toLowerCase()}/${item.slug}`}
              span={item.width}
            >
              <Card mode={mode} i={i} data={item}></Card>
            </CardWrapper>
          )
        })}
      </StyledGrid>

      {Object.keys(pages).length > 1 && (
        <Navigation>
          {pages &&
            renderPagination().map((button, i) => {
              if (button) {
                return (
                  <PaginationButton
                    onClick={() => setPageIndex(button)}
                    page={button}
                    active={button === pageIndex}
                  >
                    <Title7>{button}</Title7>
                  </PaginationButton>
                )
              }
              return
            })}
          <PaginationNext
            onClick={() => {
              if (pageIndex < Object.keys(pages).length) {
                setPageIndex(pageIndex + 1)
              }
            }}
          >
            <Title7>Next</Title7>
            <IconWrapper>
              <StaticImage alt="next" src="../images/arrow.svg" />
            </IconWrapper>
          </PaginationNext>
          <PaginationLast
            onClick={() => setPageIndex(Object.keys(pages).length)}
          >
            <IconWrapper>
              <StaticImage alt="next" src="../images/arrow.svg" />
              <StaticImage alt="next" src="../images/arrow.svg" />
            </IconWrapper>
          </PaginationLast>
        </Navigation>
      )}
    </StyledSection>
  )
}

export default List

type StyledSectionProps = {
  mode: "Dark" | "Light"
}
const StyledSection = styled.section<StyledSectionProps>`
  position: relative;

  /* min-height: 60rem; */
  padding-top: 15rem;
  padding-bottom: 25rem;
  /* overflow-x: scroll; */
  h1 {
    margin-bottom: 3rem;
  }

  * {
    color: ${(props) => (props.mode === "Dark" ? "white" : "black")};
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: 1fr;
  grid-template-rows: 30rem 30rem 30rem;

  @media (min-width: 740px) {
    grid-template-rows: ${(props) =>
      props.lessPadding ? "35rem" : "35rem 35rem"};

    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
`
type CardWrapperProps = {
  span: number
}
const CardWrapper = styled(Link)<CardWrapperProps>`
  text-decoration: none;
  grid-column: ${(props) => `span ${props.span}`};
  &:hover {
    animation: ${hoverGrow} 0.3s cubic-bezier(0.25, -1.41, 0.54, 1.68);
    animation-fill-mode: forwards;
  }
`
const Navigation = styled.div`
  height: 5rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

type PaginationButtonProps = {
  page?: number
  active?: boolean
}

const PaginationButton = styled.button<PaginationButtonProps>`
  border: ${(props) => props.page && "1px solid blue"};
  all: unset;
  cursor: pointer;
  min-width: 3rem;

  text-align: center;
  border-bottom: ${(props) => props.active && "3px solid var(--purple)"};
  height: 3rem;
`
const PaginationNext = styled(PaginationButton)`
  display: flex;

  align-items: center;
  margin-left: 1rem;

  h6 {
    color: var(--purple);
  }
`
const IconWrapper = styled.div`
  margin-left: 1.5rem;
`

const PaginationLast = styled(PaginationButton)`
  display: flex;
  align-items: center;
`
