import { graphql, useStaticQuery } from "gatsby"

export const useNews = () => {
  const allNewsEvents = useStaticQuery(graphql`
    {
      allContentfulNewsEvents(
        sort: { fields: createdAt, order: DESC }
        filter: {
          type: { eq: "News" }
          node_locale: { eq: "en-US" }
          slug: { ne: "dev-dummy" }
        }
      ) {
        nodes {
          ...Event
        }
      }
    }
  `)
  return allNewsEvents.allContentfulNewsEvents.nodes
}
