import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { TeamMember } from "../shared/ContentfulTypes"

type TeamMemberProps = {
  member: TeamMember
}
const Member: React.FC<TeamMemberProps> = ({ member }) => {
  const {
    name,
    workTitle,
    image,
    text: { text },
  } = member

  return (
    <StyledMember>
      <StyledName>{name}</StyledName>
      <h5>{workTitle}</h5>
      <StyledImage>
        <GatsbyImage
          style={{ height: "100%", width: "100%" }}
          imgStyle={{ height: "100%", width: "100%", objectFit: "cover" }}
          alt={image?.description}
          image={image.gatsbyImageData}
        />
      </StyledImage>
      <StyledText>
        <p>{text}</p>
      </StyledText>
    </StyledMember>
  )
}
const StyledMember = styled.div``
const StyledName = styled.p`
  color: var(--gold) !important;
`
const StyledImage = styled.div`
  height: 330px;
`
const StyledText = styled.div`
  margin: 32px 0;
  p {
    font-size: 16px;
  }
`
export default Member
