import { graphql } from "gatsby"
import React, { useEffect } from "react"
import PageWrapper from "../components/PageWrapper"
import { useLocaleContext } from "../context/localeContext"
import { PageStandard } from "../shared/ContentfulTypes"
import { SectionComponents } from "../utils/SectionSelector"
import { langs } from "../data/languages"
import { Path } from "typescript"

type PageProps = {
  data: PageStandard
  path: Path
}

const StandardTemplate: React.FC<PageProps> = ({ data, path }) => {
  const sections = data.contentfulPageStandard.sections
  const { seoTitle, slug, indexing, metaImage } = data.contentfulPageStandard

  const isDevelopment = process.env.GATSBY_DEVELOP
  const shouldIndex = isDevelopment ? false : indexing

  const { setLocale } = useLocaleContext()
  const seoDescription =
    data.contentfulPageStandard.seoDescription?.seoDescription || ""

  useEffect(() => {
    const locale = data.contentfulPageStandard.node_locale
    const pathWithoutPrefix = path.replace(langs[locale], "")

    setLocale({ prefix: langs[locale], path: pathWithoutPrefix })
  }, [])

  return (
    <PageWrapper
      indexing={shouldIndex}
      seo={{ seoTitle, seoDescription, slug, seoImage: metaImage?.url }}
    >
      {sections &&
        sections.map((section, i) => {
          const Component: React.ElementType =
            SectionComponents[section.__typename]
          if (!section.__typename) {
            return (
              <div key={i}>
                There is a section in contentful that you need to add a query
                for
              </div>
            )
          }
          if (!SectionComponents[section.__typename]) {
            return (
              <div key={i}>create a component for {section.__typename}</div>
            )
          } else {
            return <Component key={i} index={i} data={section} />
          }
        })}
    </PageWrapper>
  )
}

export const query = graphql`
  query MyPageQuery($id: String) {
    contentfulPageStandard(id: { eq: $id }) {
      slug
      node_locale
      indexing
      seoTitle
      seoDescription
      metaImage {
        url
      }
      sections {
        __typename
        ... on ContentfulSectionVideo {
          ...Video
        }
        ... on ContentfulSalesforceForm {
          ...SalesforceForm
        }
        ... on ContentfulSectionBanner {
          __typename
          image {
            description
            gatsbyImageData
          }
          text {
            raw
          }
        }
        ... on ContentfulSectionList {
          __typename
          type
          heading
          mode
        }
        ... on ContentfulSectionHeroNew {
          __typename
          column1 {
            raw
            references {
              ... on ContentfulSlider {
                ...Slider
              }
            }
          }
          column1Color
          column1Image {
            description
            gatsbyImageData
          }
          column2 {
            raw
            references {
              ... on ContentfulSlider {
                ...Slider
              }
            }
          }
          column2Color
          column2Image {
            description
            gatsbyImageData
          }
        }
        ... on ContentfulSectionProductSelection {
          __typename
          title
          heading
          heading2
          text {
            raw
          }
          logo {
            gatsbyImageData
          }
          headsUp {
            raw
          }
          barCodes {
            price
            donation
            barCode {
              description
              gatsbyImageData
            }

            image {
              description
              gatsbyImageData
            }
          }
        }

        ... on ContentfulSectionTryCheckout {
          __typename
          title

          text {
            raw
          }
          textMobile {
            raw
          }
          image {
            gatsbyImageData
            description
          }
          mobileUserInformationHeading
          usingMobile {
            raw
          }
          qrCode {
            description
            gatsbyImageData
          }
          url
        }
        ... on ContentfulSectionTeam {
          __typename
          heading
          subHeading
          mode
          members {
            name
            workTitle
            image {
              gatsbyImageData
            }
            text {
              text
            }
          }
        }
        ... on ContentfulSectionHero {
          __typename
          heading
          headingSize
          style
          mobileImageHomepage {
            gatsbyImageData
            description
          }
          image {
            gatsbyImageData
            file {
              url
            }
          }
          description: text
          mediaType
        }
        ... on ContentfulSectionTextImage {
          __typename
          mode
          heading
          heading2
          text {
            raw
            references {
              ... on Node {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  filename
                  file {
                    url
                  }
                  gatsbyImageData
                }
              }
            }
          }
          image {
            description
            gatsbyImageData
            file {
              url
            }
          }
          imageMobile {
            description
            gatsbyImageData
            file {
              url
            }
          }
          cta
          ctaLinksTo
          background
          display
        }
        ... on ContentfulSection4ColumnsText {
          __typename
          mode
          heading
          heading2
          column {
            raw
            references {
              ... on Node {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  gatsbyImageData
                  filename
                  file {
                    url
                  }
                }
              }
            }
          }
          column {
            raw
            references {
              ... on Node {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  gatsbyImageData
                  filename
                  file {
                    url
                  }
                }
              }
            }
          }
          column2 {
            raw
            references {
              ... on Node {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  gatsbyImageData
                  filename
                  file {
                    url
                  }
                }
              }
            }
          }
          column3 {
            raw
            references {
              ... on Node {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  gatsbyImageData
                  filename
                  file {
                    url
                  }
                }
              }
            }
          }
          column4 {
            raw
            references {
              ... on Node {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  gatsbyImageData
                  filename
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        ... on ContentfulSectionPartnerLogos {
          __typename
          mode
          images {
            file {
              url
            }
            filename
            gatsbyImageData
            description
          }
          text {
            raw
          }
        }
        ... on ContentfulSectionThreeColumnsText {
          mode
          __typename
          heading
          subHeading
          image {
            description
            gatsbyImageData
            file {
              url
            }
          }
          column {
            raw
          }
          column2 {
            raw
          }
          column3 {
            raw
          }
        }

        ... on ContentfulForm {
          ...Form
        }
      }
    }
  }
`
export default StandardTemplate
