import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

type ButtonProps = {
  type: "primary" | "secondary"
}
const Button: React.FC<ButtonProps> = ({ type, children }) => {
  return <StyledButton type={type}>{children}</StyledButton>
}

type StyledButtonProps = {
  type: "primary" | "secondary"
}
const StyledButton = styled.div<StyledButtonProps>`
  background-color: #7700e6;
  background-color: ${(props) =>
    props.type === "primary" ? "#7700E6" : "white"};
  border-radius: 30px;
  overflow: hidden;
  max-width: 400px;
  height: 60px;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 40px;
  border: 2px solid #7700e6;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: F37 Lineca;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: ${(props) => (props.type === "primary" ? "16px" : "0px")};
  a {
    color: ${(props) => (props.type === "primary" ? "white" : "#7700E6")};
    overflow: hidden;
    font-family: F37 Lineca;
    font-weight: bold;
  }
`
export default Button
