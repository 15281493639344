import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import { ContentfulSectionTextImagesText } from "../shared/ContentfulTypes"
import styled from "styled-components"
import { SectionParent } from "../shared/Styles"
import FullWidthSingleColor from "../components/FullWidthSingleColor"
import {
  richTextLinks,
  richtextDefaultOptions,
} from "../components/RichTextOptions"
type TextImagesTextProps = {
  data: ContentfulSectionTextImagesText
}
const TextImagesText: React.FC<TextImagesTextProps> = ({ data }) => {
  const { text, images, mode } = data

  return (
    <StyledSection mode={mode ? mode : "Light"}>
      <FullWidthSingleColor mode={mode ? mode : "Light"} />
      <StyledSectionCol1>
        <StyledImageContainer>
          {images.map((image, i) => {
            if (image.filename.includes(".svg")) {
              return (
                <StyledSvgWrapper key={i}>
                  <img src={image.file.url}></img>
                </StyledSvgWrapper>
              )
            } else {
              return (
                <StyledSvgWrapper key={i}>
                  <GatsbyImage
                    alt={image?.description}
                    image={image.gatsbyImageData}
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </StyledSvgWrapper>
              )
            }
          })}
        </StyledImageContainer>
      </StyledSectionCol1>
      <StyledSectionCol>
        <StyledText>{renderRichText(text, richtextDefaultOptions)}</StyledText>
      </StyledSectionCol>
    </StyledSection>
  )
}

const StyledSection = styled(SectionParent)`
  padding-top: 96px;
  padding-bottom: 180px;
  display: flex;
  justify-content: space-between;

  h2 {
    margin-bottom: 20px;
  }
  position: relative;

  @media (max-width: 768px) {
    padding: 40px 0;
    flex-direction: column-reverse;
  }
`
export const StyledSvgWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  margin-right: 20px;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`
export const StyledImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  max-width: 634px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`

export const StyledText = styled.div`
  max-width: 630px;
  h3 {
    margin-bottom: 40px;
  }

  h3 {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    letter-spacing: normal;
  }
`
export const StyledSectionCol = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const StyledSectionCol1 = styled(StyledSectionCol)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`

export default TextImagesText
