import React from "react"
import { ContentfulSectionThreeColumnsText } from "../shared/ContentfulTypes"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import { SectionSubHeading, StyledSectionTitle } from "../shared/Typograpy"
import FullWidthSingleColor from "../components/FullWidthSingleColor"
import { SectionParent } from "../shared/Styles"
import {
  richTextLinks,
  richtextDefaultOptions,
} from "../components/RichTextOptions"

type ThreeColumnsTextProps = {
  data: ContentfulSectionThreeColumnsText
}
const ThreeColumnsText: React.FC<ThreeColumnsTextProps> = ({ data }) => {
  const { column, column2, column3, image, mode } = data

  const columns = [column, column2, column3]

  return (
    <StyledSection mode={mode ? mode : "Grey"}>
      <FullWidthSingleColor mode={mode ? mode : "Grey"} />
      <ContentWrapper>
        <div>
          {data?.heading && (
            <StyledSectionTitle>{data.heading}</StyledSectionTitle>
          )}
          {data?.subHeading && <SubHeading>{data.subHeading}</SubHeading>}
          <Columns>
            {columns.map((col, index) => (
              <Column key={index}>
                {renderRichText(col, richtextDefaultOptions)}
              </Column>
            ))}
          </Columns>
        </div>

        <ImageWrapper>
          <GatsbyImage
            style={{ width: "100%", height: "100%" }}
            imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt={image?.description}
            image={image?.gatsbyImageData}
          />
        </ImageWrapper>
      </ContentWrapper>
    </StyledSection>
  )
}

const SubHeading = styled(SectionSubHeading)`
  max-width: 80%;
  min-height: 10rem;
  @media (min-width: 769px) {
    max-width: unset;
  }
`
const StyledSection = styled(SectionParent)`
  position: relative;

  h3 {
    width: 70%;
    margin-bottom: 60px;
  }
  @media (max-width: 768px) {
    padding: 0;

    h3 {
      width: 80%;
      margin: 0;
      max-width: 400px;
    }
  }
`
export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 186px 0 96px;

  h4 {
    margin-bottom: 20px;
  }
  div {
    width: 90%;
  }
  @media (max-width: 768px) {
    padding: 60px 0 60px;
    flex-direction: column-reverse;
    div {
      width: 100%;
    }
  }
`

export const Columns = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  margin-right: 50px;
  width: 100% !important;

  :last-child {
    margin-right: 0;
  }

  h6 {
    margin-bottom: 10px;
    font-family: F37 Lineca Bold, sans-serif;
  }

  p {
    font-size: 16px;
    line-height: 26px;
  }
`
export const ImageWrapper = styled.div`
  /* max-width: 400px;
  position: absolute;
  right: -100px;

  @media (max-width: 768px) {
    top: 50px;
    right: -50px;
    height: 150px;
    max-width: 150px;
  } */
  position: absolute;
  top: 50px;
  right: -50px;
  height: 150px;
  max-width: 150px;
  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 1400px) {
    display: unset;
    max-width: 400px;
    height: unset;
    right: -100px;
    top: unset;
  }
`

export default ThreeColumnsText
