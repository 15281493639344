import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import styled from "styled-components"
import FullWidthSingleColor from "../components/FullWidthSingleColor"
import { richtextDefaultOptions } from "../components/RichTextOptions"
import { ContentfulSection4ColumnsText } from "../shared/ContentfulTypes"
import { SectionParent } from "../shared/Styles"
import { SectionSubHeading, StyledSectionTitle } from "../shared/Typograpy"

type FourColumnsTextProps = {
  data: ContentfulSection4ColumnsText
}
const FourColumnsText: React.FC<FourColumnsTextProps> = ({ data }) => {
  const { heading, heading2, column, column2, column3, column4, mode } = data

  const columns = [column, column2, column3, column4]

  return (
    <StyledSection mode={mode ? mode : "Grey"}>
      <FullWidthSingleColor mode={mode ? mode : "Grey"} />
      <StyledSectionTitle>{heading}</StyledSectionTitle>
      <SectionSubHeading>{heading2}</SectionSubHeading>

      <StyledColumns>
        {columns.map((column, i) => {
          return (
            <StyledColumn key={i}>
              {renderRichText(column, richtextDefaultOptions)}
            </StyledColumn>
          )
        })}
      </StyledColumns>
    </StyledSection>
  )
}

const StyledSection = styled(SectionParent)`
  padding: var(--sectionPadding);
  width: 100%;
  height: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;

    left: 50%;
    transform: translateX(-50%);
    background: var(--dark);
    z-index: -1;
  }

  h4 {
    margin-bottom: 20px;
  }
  h2 {
    font-family: F37 Lineca, sans-serif;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
  position: relative;
`

export const StyledColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

export const StyledColumn = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 85%;

  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }

  h3 {
    font-family: F37 Lineca, sans-serif;
  }

  p {
    font-family: F37 Lineca, sans-serif;
    font-size: 16px;
    line-height: 26px;
  }

  ul {
    padding-left: 10px;
  }

  h6 {
    font-family: F37 Lineca Bold, sans-serif;
    margin-top: 60px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StyledColumnImageWrapper = styled.div``
export default FourColumnsText
