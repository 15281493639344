import React from "react"
import styled from "styled-components"
import FullWidthSingleColor from "../components/FullWidthSingleColor"
import Member from "../components/Member"

import { ContentfulSectionTeam } from "../shared/ContentfulTypes"
import { SectionParent } from "../shared/Styles"

type TeamProps = {
  data: ContentfulSectionTeam
}

const Team: React.FC<TeamProps> = ({ data }) => {
  const { heading, subHeading, members, mode } = data

  return (
    <StyledSection mode={mode ? mode : "Grey"}>
      <FullWidthSingleColor mode={mode ? mode : "Grey"} />
      <StyledText>
        <h3>{heading}</h3>
        <p>{subHeading}</p>
      </StyledText>
      <StyledMembers>
        {members.map((member) => {
          return (
            <>
              <Member member={member} />
            </>
          )
        })}
      </StyledMembers>
    </StyledSection>
  )
}
const StyledSection = styled(SectionParent)`
  padding: var(--sectionPadding);
`
const StyledText = styled.div`
  max-width: 580px;
  p {
    margin-top: 22px;
    margin-bottom: 50px;
  }
`
const StyledMembers = styled.div`
  display: grid;
  gap: 48px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const StyledMember = styled.div``
export default Team
