import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { useRef } from "react"
import styled from "styled-components"
import BarCode from "../components/BarCode"
import BarCodeSlider from "../components/BarCodeSlider"
import {
  richTextLinks,
  richtextDefaultOptions,
} from "../components/RichTextOptions"
import { ContentfulSectionPruductSelection } from "../shared/ContentfulTypes"
import { SectionSubHeading, StyledSectionTitle } from "../shared/Typograpy"

type ProductSelectionProps = {
  data: ContentfulSectionPruductSelection
}
const ProductSelection: React.FC<ProductSelectionProps> = ({ data }) => {
  const {
    heading,
    heading2,
    text,
    logo: { description, gatsbyImageData },
    headsUp,
    barCodes,
  } = data

  return (
    <StyledSection>
      <StyledWrapper>
        <StyledRichText>
          <StyledSectionTitle>{heading}</StyledSectionTitle>
          <SectionSubHeading>{heading2}</SectionSubHeading>
          {renderRichText(text, richtextDefaultOptions)}
        </StyledRichText>
        <GatsbyImage
          alt={description}
          image={gatsbyImageData}
          imgStyle={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </StyledWrapper>

      <BarCodeSlider headsUp={headsUp} barCodes={barCodes} />
    </StyledSection>
  )
}
const StyledSection = styled.section`
  min-width: 100%;
  min-height: 200px;
  padding: 48px 0;
  background: var(--grey);
  position: relative;

  h4,
  h3 {
    margin-bottom: 20px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;

    left: 50%;
    transform: translateX(-50%);
    background: var(--grey);
    z-index: -1;
  }
  @media (min-width: 1440px) {
    padding-bottom: 160px;
  }
`
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StyledRichText = styled.div`
  width: 50%;
  text-align: left;

  p {
    color: var(--dark);
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export default ProductSelection
